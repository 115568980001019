import React, { useState, useEffect, useLayoutEffect } from "react"

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Collapse,
  Label,
} from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"
import GeoEditor from "./GeoEditor.jsx"
import { deviceTypes, fromCommaList, blackStyle, whiteStyle } from "../../Utils.js"

var undef

const SocioSelect = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { code, question, responses } = props.attribute
  const [rangs, setRangs] = useState(props.rangs)

  const toggle = () => setIsOpen(!isOpen)

  const toggleRang = (rang) =>
    setRangs((arr) => {
      return arr.includes(rang) ? [...arr.filter((v) => v !== rang)] : [...arr, rang]
    })

  return (
    <>
      <div onClick={toggle} className={"m-4 cursor"} style={{ cursor: "pointer" }}>
        <u>{question}</u> ( {rangs.length} )
      </div>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            {responses.map((s) => (
              <FormGroup key={"resp" + s.rang} className="pl-md-4">
                <Label check>
                  <Input
                    type="checkbox"
                    value={s.rang}
                    name={code}
                    defaultChecked={rangs.includes(s.rang)}
                    onClick={() => toggleRang(s.rang)}
                  />
                  {s.translation}
                </Label>
              </FormGroup>
            ))}
          </CardBody>
        </Card>
      </Collapse>
    </>
  )
}

const SocioInputRadios = (props) => {
  const { input, removeInput, index, code } = props

  return (
    <>
      <input
        value="<"
        className="ml-2"
        type="radio"
        name={code + "_radio_" + index}
        defaultChecked={input.operator === "<" || input.operator === null}
      />
      <span className="ml-1 display-4">{"<"}</span>
      <input
        value=">"
        className="ml-3"
        type="radio"
        name={code + "_radio_" + index}
        defaultChecked={input.operator === ">"}
      />
      <span className="ml-1 display-4">{">"}</span>
      <input
        value="="
        className="ml-3"
        type="radio"
        name={code + "_radio_" + index}
        defaultChecked={input.operator === "="}
      />
      <span className="ml-2 display-4">=</span>

      <Button className="ml-4" onClick={() => removeInput(index)}>
        -
      </Button>
    </>
  )
}

const SocioInput = (props) => {
  const { code, question } = props.attribute

  const [inputs, setNewInput] = useState(
    props.inputs != null ? props.inputs.rules : [{ operand: null, operator: null }]
  )

  const addInput = (linecode) => {
    setNewInput((arr) => [...arr, { code: linecode, number: null, sign: null }])
  }

  const removeInput = (index) => {
    setNewInput((arr) => arr.slice(1, index + 1))
  }

  return (
    <>
      <Row>
        <Col className="pr-md-4" md="12">
          <FormGroup>
            <label className="mb-4">{question}</label>
            <Col className="pr-md-4" md="12">
              {inputs.map((input, index) => {
                return (
                  <Row
                    key={"question-" + code + "-" + index.toString()}
                    className={"row_" + code}
                  >
                    <Col md="2">
                      <Input
                        type="number"
                        name={code + "_" + index.toString()}
                        defaultValue={input.operand}
                      />
                    </Col>
                    <Col>
                      <SocioInputRadios
                        key={"SocioInputRadios" + index.toString()}
                        code={code}
                        index={index}
                        input={input}
                        addInput={addInput}
                        removeInput={removeInput}
                      />
                    </Col>
                  </Row>
                )
              })}
              <Row>
                <Button className="ml-4" onClick={() => addInput(code)}>
                  +
                </Button>
              </Row>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

const SegmentsList = ({ segmentsDatas, segments, setSegments }) => {
  if (segmentsDatas === undefined) return <></>

  return (
    <>
      <h3>Segments</h3>
      <Segment
        segments={segments}
        segmentsDatas={segmentsDatas}
        code={segmentsDatas.code}
        children={segmentsDatas.children}
        setSegments={setSegments}
      />
    </>
  )
}

const Segment = ({
  segments,
  segmentsDatas,
  code,
  children,
  setSegments,
  parent,
}) => {
  // console.log("segmentsDatas", segmentsDatas)

  const [isOpen, setIsOpen] = useState(
    segmentsDatas.code === code ||
      segments.includes(code) ||
      children.map((c) => c.code).some((r) => segments.includes(r))
      ? true
      : false
  )

  if (segmentsDatas === undefined) return <></>

  const select = (segment, childrens) => {
    if (segment === segmentsDatas.code) {
      setSegments([segment])
      setIsOpen(false)

      return
    }

    if (parent) {
      setSegments((arr) => {
        return arr.filter((s) => s !== parent)
      })
      setIsOpen(false)
    }

    setSegments((arr) => {
      const toRemove = arr.filter((s) => !childrens.includes(s))

      const newArray = toRemove.filter(
        (s) => s !== segment && s !== segmentsDatas.code
      )

      return arr.includes(segment)
        ? toRemove.filter((s) => s !== segment && s !== segmentsDatas.code)
        : [...newArray, segment]
    })
  }

  return (
    <>
      <div style={{ marginLeft: "35px", marginBottom: "5px" }}>
        <div>
          {children.length > 0 ? (
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsOpen(!isOpen)
              }}
              style={{
                all: "unset",
                marginRight: "5px",
                color: "blue",
              }}
            >
              +
            </button>
          ) : (
            <span style={{ marginRight: "16px" }}></span>
          )}{" "}
          <input
            type="checkbox"
            name="segments"
            value={code}
            checked={segments.includes(code)}
            onChange={() =>
              select(
                code,
                children.map((c) => c.code)
              )
            }
            style={{ marginRight: "5px" }}
          />
          {code} ({children.length})
        </div>
        <Collapse
          isOpen={isOpen}
          style={{ marginLeft: "35px", marginTop: "5px", marginBottom: "5px" }}
        >
          {children.map((child) => (
            <Segment
              key={"segt_" + child.code}
              segments={segments}
              segmentsDatas={segmentsDatas}
              code={child.code}
              children={child.children}
              setSegments={setSegments}
              parent={code}
            />
          ))}
        </Collapse>
      </div>
    </>
  )
}

const getSegments = async () => {
  try {
    const taxoUrl = process.env.REACT_APP_TAXONOMY_URL + "/taxonomy"

    const response = await fetch(taxoUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const json = await response.json()

    // console.log(json)

    return json
  } catch (e) {
    return null
  }
}

const getAttributes = async () => {
  try {
    const attrUrl = process.env.REACT_APP_TAXONOMY_URL + "/socio-demo"

    const response = await fetch(attrUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const json = await response.json()

    // console.log(json)

    return json
  } catch (e) {
    return null
  }
}

const TargetEditor = (props) => {
  const [segmentsDatas, setSegmentsDatas] = useState()
  const [attributesDatas, setAttributesDatas] = useState([])

  useLayoutEffect(() => {
    // console.log("Component is mounted in the DOM")
    getSegments().then((value) => setSegmentsDatas(value))
    getAttributes().then((value) => setAttributesDatas(value))
  }, [])

  const [count, setCount] = useState(0)
  const [target, setTarget] = useState(props.target)
  const [geo, setGeo] = useState([])
  const [zoom, setZoom] = useState(1)

  const [oldValues, setOldValues] = useState(
    JSON.parse(JSON.stringify(props.target.geo))
  )
  const [center, setCenter] = useState([44.414165, 8.942184])

  const [segments, setSegments] = useState(props.target.segments ?? [])

  useEffect(() => {
    if (
      props.target.geo !== undef &&
      props.target.geo.length !== 0 &&
      props.target.geo[0] !== 0
    ) {
      setGeo(props.target.geo)
      setZoom(8)
      setCenter([props.target.geo[0], props.target.geo[1]])
    } else {
      setGeo([])
      setZoom(1)
      setCenter([44.414165, 8.942184])
    }
  }, [props.target.geo])

  const nameChangedHandler = (event) => {
    target.name = event.target.value
    setTarget(target)
  }

  const domainType = () => {
    if (target.domain_targetting === "BLACKLIST")
      return (
        <>
          <option>BLACKLIST</option>
          <option>WHITELIST</option>
        </>
      )
    return (
      <>
        <option>BLACKLIST</option>
        <option>WHITELIST</option>
      </>
    )
  }

  const getDeviceTypes = (s) => {
    var checks = []
    if (s !== undef && s.length > 0) {
      checks = s.split(",")
    }
    var items = []
    for (var i = 1; i < deviceTypes.length; i++) {
      var dt = deviceTypes[i]
      if (checks.indexOf(dt) > -1)
        items.push(
          <option key={"dt-" + i} selected>
            {dt}
          </option>
        )
      else
        items.push(
          <option key={"iab-" + i} dt={"iab-" + i}>
            {dt}
          </option>
        )
    }
    return items
  }

  const getWBList = (s) => {
    var checks = []
    if (s.length > 0) {
      checks = s.split(",")
    }
    var items = []
    for (var i = 1; i < 26; i++) {
      var iab = "IAB" + i
      if (checks.indexOf(iab) > -1)
        items.push(
          <option key={"iab-" + i} selected>
            {iab}
          </option>
        )
      else items.push(<option key={"iab-" + i}>{iab}</option>)
    }
    return items
  }

  const redraw = () => {
    setCount(count + 1)
  }

  const update = () => {
    var dv = document.getElementById("domain").value
    var bd = document.getElementById("set").value
    var type = document.getElementById("domaintype").value
    target.name = document.getElementById("name").value
    if (dv === "") target.list_of_domains = null
    else target.list_of_domains = dv.split("\n").join(",")
    if (bd === "") target.listofdomainsSYMBOL = null
    else target.listofdomainsSYMBOL = "$" + bd
    if (dv !== "" || bd !== "") {
      target.domain_targetting = type
    } else target.domain_targetting = null

    var country = document.getElementById("country").value

    if (country === "" || country === "null") target.country = undef
    else target.country = country

    var car = document.getElementById("carrier").value
    var os = document.getElementById("os").value
    var make = document.getElementById("makes").value
    var model = document.getElementById("models").value
    target.devicetype = [...document.getElementById("device-types").options]
      .filter((x) => x.selected)
      .map((x) => x.value)
      .join()

    if (car === "" || car === "null") target.carrier = undef
    else target.carrier = car
    if (os === "" || os === "null") target.os = undef
    else target.os = os
    if (make === "" || make === "null") target.make = undef
    else target.make = make.split("\n").join(",")
    if (model === "" || make === "null") target.model = undef
    else target.model = model.split("\n").join(",")

    target.iab_category = [...document.getElementById("whitelist").options]
      .filter((x) => x.selected)
      .map((x) => x.value)
      .join()
    target.iab_category_blklist = [...document.getElementById("blacklist").options]
      .filter((x) => x.selected)
      .map((x) => x.value)
      .join()

    target["selects"] = []
    target["inputs"] = []
    // social select

    const socialSelect = attributesDatas
      .filter((doc) => doc.type === "SELECT")
      .map((doc) => doc.code)

    socialSelect.forEach((elt) => {
      let arrayChecked = document.querySelectorAll(
        'input[name="' + elt + '"]:checked'
      )

      if (arrayChecked) {
        target["selects"].push({
          code: elt,
          rangs: [...Array.from(arrayChecked).map((x) => Number(x.value))],
        })
      }
    })

    // end social select

    // social inputs
    // [ { 50000, '>'}, {70000, '<'}]
    const socialInputs = attributesDatas
      .filter((doc) => doc.type === "INPUT")
      .map((doc) => doc.code)

    socialInputs.forEach((code) => {
      let rows = document.querySelectorAll(".row_" + code)

      let rules = []

      rows.forEach((r) => {
        let inputNumberElt = r.querySelector('input[type="number"]')
        let inputRadioElt = r.querySelector('input[type="radio"]:checked')

        if (inputNumberElt && inputRadioElt) {
          if (inputNumberElt.value && inputRadioElt.value) {
            rules.push({
              operand: inputNumberElt.value,
              operator: inputRadioElt.value,
            })
          }
        }
      })

      target["inputs"].push({
        code,
        rules: rules,
      })
    })
    // end social inputs

    // return false
    target["segments"] = segments

    // console.log("newTarget", target)

    props.callback(target)
  }

  const getLabel = () => {
    if (target.id === 0) return <div>Save</div>
    return <div>Update</div>
  }

  const updateGeo = (pos) => {
    if (pos === undef) {
      if (oldValues === undef) pos = []
      else pos = oldValues
    }

    for (var i = pos.length - 3; i >= 0; i -= 3) {
      if (pos[i] === 0) {
        pos.splice(i, 3)
      }
    }
    target.geo = pos
    setTarget(target)
    redraw()
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">Edit Targeting Details</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>SQL ID (disabled)</label>
                        <Input
                          style={
                            document.body.classList.contains("white-content")
                              ? blackStyle
                              : whiteStyle
                          }
                          defaultValue={target.id}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <label>Target Name</label>
                        <Input
                          id="name"
                          defaultValue={target.name}
                          placeholder="Target Name (Required)"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-3" md="4">
                      <FormGroup>
                        <label>Domain Values</label>
                        <Input
                          type="textarea"
                          id="domain"
                          defaultValue={fromCommaList(target.list_of_domains)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Use Big Data Set for Domain Values</label>
                        <Input
                          type="input"
                          id="set"
                          defaultValue={target.listofdomainsSYMBOL}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="2">
                      <FormGroup>
                        <label>Type</label>
                        <Input
                          type="select"
                          id="domaintype"
                          defaultValue={
                            target.domain_targetting === "BLACKLIST"
                              ? "BLACKLIST"
                              : "WHITELIST"
                          }
                        >
                          {domainType()}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          type="textarea"
                          id="country"
                          spellCheck={false}
                          placeholder="ISO-3 Countries."
                          defaultValue={fromCommaList(target.country)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="10">
                      <FormGroup>
                        <label>Geographical Boundaries</label>
                        <Alert color="warning">
                          <GeoEditor
                            key={"geoeditor-" + count}
                            rule={false}
                            callback={updateGeo}
                            geo={props.target.geo}
                            setZoom={setZoom}
                            zoom={zoom}
                            center={center}
                            setCenter={setCenter}
                          />
                        </Alert>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>Carrier</label>
                        <Input
                          type="input"
                          id="carrier"
                          defaultValue={target.carrier}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>Operating System</label>
                        <Input type="input" id="os" defaultValue={target.os} />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="2">
                      <FormGroup>
                        <label>Make</label>
                        <Input
                          type="textarea"
                          id="makes"
                          spellCheck={false}
                          defaultValue={fromCommaList(target.make)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>models</label>
                        <Input
                          type="textarea"
                          id="models"
                          spellCheck={false}
                          placeholder="Models, one per line"
                          defaultValue={fromCommaList(target.model)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="2">
                      <FormGroup>
                        <label>Device Types</label>
                        <Input type="select" id="device-types" multiple>
                          {getDeviceTypes(target.devicetypes_str)}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>IAB Whitelist</label>
                        <Input type="select" id="whitelist" multiple>
                          {getWBList(target.iab_category)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>IAB Blacklist</label>
                        <Input type="select" id="blacklist" multiple>
                          {getWBList(target.iab_category_blklist)}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  {attributesDatas
                    .filter((attr) => attr.type === "SELECT")
                    .map((attribute) => {
                      return (
                        <SocioSelect
                          key={"SocioSelect" + attribute.code}
                          attribute={attribute}
                          rangs={
                            props.target.selects.find(
                              (doc) => doc.code === attribute.code
                            )?.rangs ?? []
                          }
                        />
                      )
                    })}

                  {attributesDatas
                    .filter((attr) => attr.type === "INPUT")
                    .map((attribute, index) => {
                      return (
                        <SocioInput
                          key={"SocioInput" + index.toString()}
                          attribute={attribute}
                          inputs={props.target.inputs.find(
                            (doc) => doc.code === attribute.code
                          )}
                        />
                      )
                    })}

                  <SegmentsList
                    segmentsDatas={segmentsDatas}
                    segments={segments}
                    setSegments={setSegments}
                  />
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={() => update()}
                >
                  Save
                </Button>
                <Button
                  className="btn-fill"
                  color="danger"
                  type="submit"
                  onClick={() => props.callback(null)}
                >
                  Discard
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default TargetEditor
